import { useState } from 'react';
import dynamic from 'next/dynamic';

import { Search } from '@/icons';

import type { FunctionComponent } from 'react';
import type { TypeFacadeSearchBoxDesktopProps } from './types';

const SearchBoxInstantSearch = dynamic(
  () => import('@/components/algolia/search-box-instant-search'),
  { ssr: false },
);

/**
 * FacadeSearchBoxDesktop
 */
export const FacadeSearchBoxDesktop: FunctionComponent<
  TypeFacadeSearchBoxDesktopProps
> = ({ placeholder, className }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <div className={`h-[53px] border-b border-b-primary-200 pb-4 ${className}`}>
      {clicked ? (
        <SearchBoxInstantSearch placeholder={placeholder} />
      ) : (
        <div
          className="relative flex w-[360px] flex-row items-center gap-2 rounded-full bg-primary-100 pl-4 pr-8"
          onClick={() => setClicked(true)}
        >
          <Search width={20} height={20} className="flex-shrink-0" />

          <input
            placeholder={placeholder}
            type="text"
            className="u-body u-body--m block w-full border-0 bg-primary-100 py-2 align-baseline text-typo-primary placeholder-typo-tertiary focus:border-transparent focus:text-primary-900 focus:outline-none focus:ring-0"
          />
        </div>
      )}
    </div>
  );
};

FacadeSearchBoxDesktop.displayName = 'FacadeSearchBoxDesktop';
