import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { BlockProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const Block: FunctionComponent<BlockProps> = ({
  block,
  parentMenuName,
}: BlockProps) => (
  <>
    {block.name ? (
      <ConditionalWrapper
        condition={Boolean(block.href)}
        wrapper={(children) => (
          <NextLink href={block.href ?? ''}>
            <a>{children}</a>
          </NextLink>
        )}
      >
        <p
          id={`${block.name}-${parentMenuName}-heading`}
          className="u-body u-body--m mb-4 font-medium normal-case text-primary-900"
        >
          {block.name}
        </p>
      </ConditionalWrapper>
    ) : null}

    {block.subItems && (
      <ul
        role="list"
        aria-labelledby={`${block.name}-${parentMenuName}-heading`}
        className="mb-2.5 space-y-1.5"
      >
        {block.subItems.map((item) => (
          <li key={item.name} className="flex">
            <NextLink href={item.href}>
              <a
                href={item.href}
                className="u-body u-body--xs my-0 normal-case text-primary-500 hover:text-primary-900"
              >
                {item.name}
              </a>
            </NextLink>
          </li>
        ))}
      </ul>
    )}

    {block.lastItem && (
      <NextLink href={block.lastItem.href}>
        <a>
          <p className="u-body u-body--s mb-4 normal-case text-primary-900 hover:text-primary-500">
            {block.lastItem.name}
          </p>
        </a>
      </NextLink>
    )}
  </>
);
