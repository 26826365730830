import * as Icons from '@/icons';

import type { FunctionComponent } from 'react';
import type { FooterMobileProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const FooterMobile: FunctionComponent<FooterMobileProps> = ({
  button,
  socialNetworks,
}: FooterMobileProps) => (
  <div>
    {button}

    <div className="flex w-full justify-around py-4">
      {// TODO: Añadir tipos
      socialNetworks?.map((item: any) => {
        const Icon = Icons[item.icon as keyof typeof Icons];

        return Icon ? (
          <a key={item.icon} href={item.link}>
            <div>
              <span className="sr-only">{item.icon}</span>
              <Icon className="h-6 w-6" aria-hidden="true" />
            </div>
          </a>
        ) : null;
      })}
    </div>
  </div>
);
